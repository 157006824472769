
import { BButton, BCard } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';
import VueRecaptcha from 'vue-recaptcha';

@Component({
    components: {
        'b-card': BCard,
        'b-button': BButton,
        'vue-recaptcha': VueRecaptcha,
    },
})
export default class BaseTokenLaunchSubscribe extends Vue {
    isSubmitDisabled = true;
    mounted() {
        if (this.$route.hash === '#subscribe') {
            const element = this.$refs['subscribe'] as HTMLElement;
            this.$Lazyload.$on('loaded', (event: any) => {
                if (event.src.startsWith('/img/thx_jumbotron_token_bg')) {
                    window.scrollTo(0, element.offsetTop);
                }
            });
        }
    }
}
