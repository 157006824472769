
import { BButton, BCard } from 'bootstrap-vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        'b-card': BCard,
        'b-button': BButton,
    },
})
export default class BaseTokenDistribution extends Vue {}
